import type { ReactNode } from 'react'
import { useMemo, type FC } from 'react'
import { useRouter } from 'next/router'

import Media from '@segment/matcha/components/Media'

import type { UtilityNavProps } from '@segment/matcha/recipes/Navigation/types'

import { useGatedContent } from 'contexts/gatedContent'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import MobileNavigation from './MobileNavigation'
import DesktopNavigation from './DesktopNavigation'

interface NavigationBrandGrowthMastersProps extends UtilityNavProps {
  after?: ReactNode
}

const NavigationBrandGrowthMasters: FC<NavigationBrandGrowthMastersProps> = ({ announcement, after }) => {
  const { basePath } = usePageConfig()

  const { showContent } = useGatedContent()
  const router = useRouter()

  const demoButton = useMemo(() => {
    const copy = {
      text: ``,
      url: ``,
    }

    if (showContent || router.pathname.includes(`sign-up`)) {
      copy.text = `Grow with Segment`
      copy.url = getAbsoluteUrl(`/demo/`, { baseUrl: `https://segment.com`, basePath: `` })
    } else {
      copy.text = `Sign up`
      copy.url = `${basePath}/growth-masters/sign-up/`
    }

    return copy
  }, [showContent, basePath, router])

  return (
    <>
      <Media lessThan="md">
        <MobileNavigation demoButton={demoButton} announcement={announcement} after={after} />
      </Media>
      <Media greaterThanOrEqual="md">
        <DesktopNavigation demoButton={demoButton} announcement={announcement} after={after} />
      </Media>
    </>
  )
}

export default NavigationBrandGrowthMasters
