import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import type { PageConfig } from '@segment/contexts/page'

const data = (pageConfig: PageConfig) => [
  {
    label: `Home`,
    id: `growth-masters-home`,
    href: getAbsoluteUrl(`/growth-masters/`, pageConfig),
  },
  {
    label: `Curriculum`,
    id: `curriculum`,
    href: getAbsoluteUrl(`/growth-masters/curriculum/`, pageConfig),
  },
  {
    label: `Speakers`,
    id: `speakers`,
    href: getAbsoluteUrl(`/growth-masters/speakers/`, pageConfig),
  },
  {
    label: `Contact`,
    id: `contact`,
    href: getAbsoluteUrl(`/contact/`, { baseUrl: `https://segment.com`, basePath: `` }),
  },
]

export default data
