/* eslint-disable camelcase */
import type { FC } from 'react'

import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SignupButton from '@segment/matcha/recipes/SignupButton'

const copy = {
  heading: `Want to learn more about Twilio Segment?`,
  buttonText: `Create a free account`,
  description: `Last year, Twilio Segment helped companies implement 86,551 tools, save over 4,000 years of engineering time, and process about 130,000 user events per second. As a part of that, Segment has enabled thousands of PMs to generate accurate customer insights, build better products, and grow their business by democratizing access to data.<br><br>We want to help you, too.`,
}

const WantToLearnMore: FC = () => {
  return (
    <StyledSectionBase variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h3" variant="h2">
            {copy.heading}
          </CopyText.Heading>
          <ButtonWrapper>
            <SignupButton color="primary" additionalParams={{ utm_campaign: `pm_program`, utm_medium: `web` }}>
              {copy.buttonText}
            </SignupButton>
          </ButtonWrapper>
          <StyledCopyTextDescription tag="p" variant="pLarge" dangerouslySetInnerHTML={{ __html: copy.description }} />
        </CopyTextBase>
      </Section.Copy>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    gap: 0;
  }
`

const ButtonWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 56px;
`

const StyledCopyTextDescription = styled(CopyText.Description)`
  margin: 0 auto;
  max-width: 780px;
`

export default WantToLearnMore
