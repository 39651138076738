import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import type { FC, PropsWithChildren } from 'react'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import Divider from '@segment/matcha/components/Divider'

import FooterCompact from '@segment/templates/FooterCompact'

import { PageConfigProvider, usePageConfig } from '@segment/contexts/page'

import { default as LayoutBase } from '@segment/next-shared/layout'

import Navigation from '../Navigation'
import Subnavigation from '../Subnavigation'

import OgGrowthMasters from './assets/OgGrowthMasters.png'

interface LayoutProps {
  subnav?: boolean
  pageName: string
}

// GrowthMasters pages are hosted on the different domain then the rest of the app
// so we don't want to use an absolute urls for the links
const customPageConfig = {
  baseUrl: ``,
}

const Layout: FC<PropsWithChildren<LayoutProps>> = ({ subnav = true, pageName = ``, children }) => {
  const defaultPageConfig = usePageConfig()
  const pageConfig = {
    ...defaultPageConfig,
    ...customPageConfig,
  }

  const theme = useTheme()

  return (
    <PageConfigProvider value={pageConfig}>
      <AppearanceProvider appearance="twilioLight">
        <LayoutBase
          pageTitle={`Growth Masters${pageName && ` - ${pageName}`} | Twilio Segment`}
          pageOG={OgGrowthMasters}
          pageDescription="Our culture of close internal alignment, constant communication, and shared incentives delivers sourcing, information, and due diligence advantages to our partners."
          themeColor={theme.palette.twilio.gray[10]}
        >
          <Navigation after={subnav && <Subnavigation />} />
          <Main>
            {children}
            <WrapperDivider>
              <StyledDivider />
            </WrapperDivider>
          </Main>

          <FooterCompact />
        </LayoutBase>
      </AppearanceProvider>
    </PageConfigProvider>
  )
}

const Main = styled.main`
  background: ${({ theme }) => theme.palette.twilio.gray[10]};
`

const WrapperDivider = styled.div`
  padding: 10px 40px 0;
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding: 10px 80px 0;
  }
`

const StyledDivider = styled(Divider)`
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 1280px;
  }
`

export default Layout
