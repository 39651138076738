import { useCookie } from 'react-use'
import { useEffect, useState } from 'react'

import { USER_PROFILE_COOKIE } from '@segment/api/staticSiteBackend/segmentProfilesApi'

const useInitialCookie = (): [boolean, boolean, Function] => {
  const [profileCookie] = useCookie(USER_PROFILE_COOKIE)
  const [isLoading, setLoading] = useState(true)
  const [ungate, setUngate] = useState(false)

  useEffect(() => {
    setUngate(Boolean(profileCookie))
    setLoading(false)
  }, [profileCookie])

  return [ungate, isLoading, setUngate]
}

export default useInitialCookie
