import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useInfiniteQuery } from '@tanstack/react-query';
/**
 * Creates infinite query hook from query factory
 *
 * @param fetchFactory factory function creating [query key, fetchFunction] tuple for use with react-query lib
 * @param useClientInstance hook function returning client instance to use with fetching function
 * @returns hook function wrapping useInfiniteQuery
 */

var createInfiniteQueryHook = function createInfiniteQueryHook(fetchFactory, useClientInstance, defaultOptions) {
  return function (_ref) {
    var args = _ref.args,
        options = _ref.options;
    var instance = useClientInstance();
    return useInfiniteQuery.apply(void 0, _toConsumableArray(fetchFactory.apply(void 0, [instance].concat(_toConsumableArray(args)))).concat([_objectSpread(_objectSpread({}, defaultOptions), options)]));
  };
};

export default createInfiniteQueryHook;