/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export interface GatedContent {
  showContent: boolean
  updateRegistered: Function
}

export const GatedContentContext = createContext<GatedContent>({
  showContent: false,
  updateRegistered() {},
})
export const GatedContentProvider = GatedContentContext.Provider

export const useGatedContent = () => {
  const gatedContent = useContext(GatedContentContext)

  if (!gatedContent) {
    throw new Error(`Gated content isn't available. Please set it via GatedContentContext component.`)
  }

  return gatedContent
}
