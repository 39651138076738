import type { FC, ReactNode } from 'react'

import SegmentLogo from '@segment/matcha/components/SegmentLogo'
import Button from '@segment/matcha/components/Button'

import UtilityNav from '@segment/matcha/recipes/Navigation/MobileNavigation/UtilityNav'
import MobileNavBase, * as MobileNav from '@segment/matcha/recipes/Navigation/MobileNavigation'
import * as Navbar from '@segment/matcha/recipes/Navigation/MobileNavigation/Navbar'

import type { UtilityNavProps } from '@segment/matcha/recipes/Navigation/types'

interface MobileNavigationProps extends UtilityNavProps {
  demoButton: {
    text: string
    url: string
  }
  after?: ReactNode
}

const MobileNavigation: FC<MobileNavigationProps> = ({ announcement, demoButton, after }) => {
  return (
    <MobileNavBase>
      {announcement && <UtilityNav announcement={announcement} />}

      <MobileNav.Navbar>
        <Navbar.Brand href="/">
          <SegmentLogo alt="Twilio, Inc." />
        </Navbar.Brand>
        <Button color="primary" size="medium" variant="filled" expanded={true} href={demoButton.url}>
          {demoButton.text}
        </Button>
      </MobileNav.Navbar>
      {after}
    </MobileNavBase>
  )
}

export default MobileNavigation
