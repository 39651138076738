import type { FC } from 'react'

import SubnavBase, * as Subnav from '@segment/matcha/recipes/Subnav'

import { usePageConfig } from '@segment/contexts/page'

import data from './data'

const copy = {
  title: `Growth Masters`,
}

const Subnavigation: FC = () => {
  const pageConfig = usePageConfig()

  return (
    <SubnavBase aria-label={copy.title} label={copy.title}>
      {data(pageConfig).map((item) => (
        <Subnav.Item key={item.label} label={item.label} href={item.href} />
      ))}
    </SubnavBase>
  )
}

export default Subnavigation
