import type { FC, ReactNode } from 'react'

import SegmentLogo from '@segment/matcha/components/SegmentLogo'
import Button from '@segment/matcha/components/Button'

import * as Navbar from '@segment/matcha/recipes/Navigation/DesktopNavigation/Navbar'
import DesktopNavBase, * as DesktopNav from '@segment/matcha/recipes/Navigation/DesktopNavigation'

import type { UtilityNavProps } from '@segment/matcha/recipes/Navigation/types'

interface DesktopNavigationProps extends UtilityNavProps {
  demoButton: {
    text: string
    url: string
  }
  after?: ReactNode
}

const DesktopNavigation: FC<DesktopNavigationProps> = ({ announcement, demoButton, after }) => {
  return (
    <DesktopNavBase>
      {announcement && <DesktopNav.UtilityNav announcement={announcement} />}

      <DesktopNav.Navbar>
        <Navbar.Brand href="/">
          <SegmentLogo alt="Twilio, Inc." />
        </Navbar.Brand>
        <Navbar.Aside>
          <Button color="primary" size="medium" variant="filled" href={demoButton.url}>
            {demoButton.text}
          </Button>
        </Navbar.Aside>
      </DesktopNav.Navbar>
      {after}
    </DesktopNavBase>
  )
}

export default DesktopNavigation
